<template>
  <v-row>
    <v-col cols="12">
      <form-field-text
        v-model="obFirm.address"
        label="address"
        :required="requireDgi"
        @blur="onCopyFirmData('address')"
      />
    </v-col>

    <v-col cols="12">
      <form-field-text v-model="obFirm.address2" label="secondary.address" />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <form-field-country
        :required="requireDgi"
        v-model="obFirm.country_id"
        :codes="validCountryCodes"
        @change="onSelectCountry"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <v-slide-y-transition leave-absolute>
        <form-field-text
          v-if="!states.length"
          v-model="obFirm.state_text"
          label="location.state"
          rules=""
        />
        <form-field-state
          v-else
          :country-id="obFirm.country_id"
          :required="requireDgi"
          v-model="obFirm.state_id"
          @change="onSelectState"
        />
      </v-slide-y-transition>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <v-slide-y-transition leave-absolute>
        <form-field-text
          v-if="!towns.length"
          v-model="obFirm.town_text"
          label="location.town"
          rules=""
        />
        <form-field-town
          v-else
          :state-id="obFirm.state_id"
          :required="requireDgi"
          v-model="obFirm.town_id"
          @change="onSetLocation($event, 'town')"
        />
      </v-slide-y-transition>
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <form-field-text v-model="obFirm.cp" label="postal.code" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Mixins, Component, Prop } from "vue-property-decorator";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";

import FormFieldCountry from "@/components/form/fields/Country.vue";
import FormFieldState from "@/components/form/fields/State.vue";
import FormFieldTown from "@/components/form/fields/Town.vue";
import Utils, { IDListKey } from "@/services/Utils";
import { LocationModule } from "@/store/location";

@Component({
  components: { FormFieldState, FormFieldTown, FormFieldCountry },
})
export default class CustomersLocation extends Mixins(CustomerMixin) {
  @Prop(Boolean) readonly requireDgi!: boolean;

  onSelectCountry(iValue: number) {
    LocationModule.loadStates(iValue);
    LocationModule.towns.clear();
  }

  onSelectState(iValue: number) {
    LocationModule.loadTowns(iValue);
    this.onSetLocation(iValue, "state");
  }

  get validCountryCodes() {
    if (!this.obFirm.doc_type) {
      return [];
    }

    return Utils.validateCountryByID(this.obFirm.doc_type as IDListKey);
  }
}
</script>
